import { createAction, props } from '@ngrx/store';
import {HouseSummary} from '../../interfaces/house-summary.interface';

export enum HouseSummaryActionsTypes {
    getHouseSummary = '[HouseSummary] Get housesummary',
    successGetHouseSummary = '[HouseSummary] Success get housesummary',
    errorGetHouseSummary = '[HouseSummary] Error Get HouseSummary',

}

export const GetHouseSummary = createAction(HouseSummaryActionsTypes.getHouseSummary, props<{ id: string }>());
export const successGetHouseSummary = createAction(HouseSummaryActionsTypes.successGetHouseSummary, props<{ housesummary: Array<HouseSummary> }>());
export const errorGetHouseSummary = createAction(HouseSummaryActionsTypes.errorGetHouseSummary, props<{ payload: any }>());
